// i18next-extract-mark-ns-start woocommerce-payment-gateway
import {AnchorLink} from 'components/AnchorLink';
import {Background} from 'components/Background';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {List} from 'components/List';
import {Partners} from 'components/Partners';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql} from 'gatsby';
import {Link, Trans, useI18next} from 'gatsby-plugin-react-i18next';

//Partners
import index2 from 'images/best_payment_gateway_rates.svg';

//Images
import woocommerce_monei from 'images/woocommerce_monei.svg';

// Clients
import index1 from 'images/payment_gateway.svg';
import index3 from 'images/shopify_payment_gateway.svg';

import React from 'react';
import styled from 'styled-components';
import {IndexImage} from 'components/landings/IndexImage';
import {IndexBackground} from 'components/landings/IndexBackground';
import {InternalPageLink} from 'components/links/Pages';
import {BlogLink} from '../components/links/Blog';
import {SupportLink} from '../components/links/Support';
import {FaqsSection} from '../components/FaqsSection';
import {ButtonLink} from '../components/Button';

const IndexContent = styled.div`
  padding-top: 90px;
  max-width: 500px;
  @media (max-width: 768px) {
    padding-top: 10px;
  }
`;

const SellingPage: React.FC = () => {
  const {t} = useI18next();

  const faqs = [
    {
      header: t('What is a WooCommerce payment gateway?'),
      text: t(
        "A WooCommerce payment gateway (like MONEI) is a service or software that facilitates the secure transfer of funds from a customer's bank account or credit card to your business bank account, enabling online transactions on a WooCommerce-powered e-commerce website."
      ),
      content: (
        <Trans>
          A WooCommerce <BlogLink slug="what-is-a-payment-gateway">payment gateway</BlogLink> (like
          MONEI) is a service or software that facilitates the secure transfer of funds from a
          customer's bank account or credit card to your business bank account, enabling online
          transactions on a WooCommerce-powered e-commerce website.
        </Trans>
      )
    },
    {
      header: t('How does a WooCommerce payment gateway work?'),
      text: t(
        "When a customer makes a purchase through your WooCommerce store, the payment gateway (in this case, MONEI) encrypts the customer's payment details and securely transmits them to the payment processor. The payment processor then communicates with the customer's bank or credit card company to authorize and complete the transaction."
      ),
      content: (
        <Trans>
          When a customer makes a purchase through your WooCommerce store, the payment gateway (in
          this case, MONEI) encrypts the customer's payment details and securely transmits them to
          the payment processor. The payment processor then communicates with the customer's bank or
          credit card company to authorize and complete the transaction.
        </Trans>
      )
    },
    {
      header: t('How do I set up a WooCommerce payment gateway like MONEI?'),
      text: t(
        'To set up MONEI’s payment gateway in your WooCommerce store, create an account. Then follow the WooCommerce plugin installation instructions.'
      ),
      content: (
        <Trans>
          To set up MONEI’s payment gateway in your WooCommerce store,{' '}
          <AnchorLink href="https://dashboard.monei.com/?action=signUp">
            create an account
          </AnchorLink>
          . Then follow the{' '}
          <AnchorLink href="https://wordpress.org/plugins/monei/#installation">
            WooCommerce plugin installation instructions
          </AnchorLink>
          .
        </Trans>
      )
    },
    {
      header: t('Does MONEI have a WooCommerce subscription plugin?'),
      text: t(
        'No, but you don’t need one. Our payment gateway integrates with WooCommerce Susbscriptions extension so you can accept more subscription payment methods.'
      ),
      content: (
        <Trans>
          No, but you don’t need one. Our payment gateway integrates with{' '}
          <AnchorLink href="https://woocommerce.com/products/woocommerce-subscriptions/">
            WooCommerce Susbscriptions extension
          </AnchorLink>{' '}
          so you can accept more subscription payment methods.
        </Trans>
      )
    },
    {
      header: t(
        'What are the transaction fees associated with MONEI’s WooCommerce payment gateway?'
      ),
      text: t(
        'The fees vary depending on the payment method and they are dynamic. As your sales increase, transaction fees decrease in real-time.'
      ),
      content: (
        <Trans>
          The fees vary depending on the{' '}
          <SupportLink slug="/categories/360003378957">payment method</SupportLink> and they are
          dynamic. As your sales increase,{' '}
          <SupportLink slug="/articles/360017954318">transaction fees</SupportLink> decrease in
          real-time.
        </Trans>
      )
    },
    {
      header: t('Can I accept international payments with MONEI’s WooCommerce payment gateway?'),
      text: t(
        'Yes, you can configure multiple currencies in your MONEI account. You can select a default currency while you’re creating your account and then contact support to add more currencies.'
      ),
      content: (
        <Trans>
          Yes, you can configure{' '}
          <SupportLink slug="/articles/360017801897">multiple currencies</SupportLink> in your MONEI
          account. You can select a default currency while you’re creating your account and then{' '}
          <SupportLink slug="/requests/new">contact support</SupportLink> to add more currencies.
        </Trans>
      )
    },
    {
      header: t('Is MONEI’s WooCommerce payment gateway secure?'),
      text: t('Yes, MONEI is PCI DSS and PSD2 compliant.'),
      content: (
        <Trans>
          Yes, MONEI is <InternalPageLink slug="pci-dss">PCI DSS</InternalPageLink> and{' '}
          <BlogLink slug="psd2">PSD2</BlogLink> compliant.
        </Trans>
      )
    },
    {
      header: t(
        'Can I customize the checkout process with MONEI’s WooCommerce payment gateway plugin?'
      ),
      text: t(
        'Yes, customize your checkout page by adding your logo and branding, and use your company URL instead of redirecting customers to a third-party domain.'
      ),
      content: (
        <Trans>
          Yes, <SupportLink slug="/articles/360017814717">customize your checkout page</SupportLink>{' '}
          by adding your logo and branding, and use your company URL instead of redirecting
          customers to a third-party domain.
        </Trans>
      )
    },
    {
      header: t(
        'How long does it take to receive funds for transactions made via MONEI’s WooCommerce payment gateway plugin?'
      ),
      text: t(
        'Settlement times generally range from one to seven days from the date of the transaction. But once you’re a verified and trusted merchant, you’ll be eligible for 1-day payment settlements.'
      ),
      content: (
        <Trans>
          Settlement times generally range from one to seven days from the date of the transaction.
          But once you’re a verified and trusted merchant, you’ll be eligible for 1-day payment
          settlements.
        </Trans>
      )
    }
  ];
  const schemaFaqs = {
    '@type': 'FAQPage',
    mainEntity: faqs.map((faq) => ({
      '@type': 'Question',
      name: faq.header,
      acceptedAnswer: {
        '@type': 'Answer',
        text: faq.text
      }
    }))
  };

  return (
    <>
      <SEO
        title={t('WooCommerce Payment Gateway')}
        description={t(
          'Grow your business with MONEI’s WooCommerce payment gateway: Accept all major payment methods. Quick and easy integration. Dynamic pricing. Sign up now »'
        )}
        bodyAttributes={{class: 'selling-page'}}
        schema={schemaFaqs}
      />
      <IndexBackground>
        <Content>
          <Section reverseOnMobile>
            <IndexContent>
              <SectionHeader underline tagName="h1">
                <Trans>WooCommerce payment gateway</Trans>
              </SectionHeader>
              <Trans parent="p">
                WooCommerce is the leading WordPress e-commerce solution. Use a simple{' '}
                <AnchorLink href="https://wordpress.org/plugins/monei/">plugin</AnchorLink> to
                quickly and easily integrate your online store with MONEI’s WooCommerce{' '}
                <InternalPageLink slug="payment-gateway">payment gateway</InternalPageLink>. Start
                accepting secure digital payments today.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </IndexContent>
            <IndexImage
              src={woocommerce_monei}
              alt="MONEI payment gateway"
              title="MONEI payment gateway"
            />
          </Section>
        </Content>
      </IndexBackground>
      <Background>
        <Content>
          <Section>
            <SectionImage
              src={index2}
              alt="MONEI Payment Gateway"
              title="MONEI Payment Gateway"
              width={600}
              height={350}
              mobileWidth={280}
            />
            <div>
              <SectionHeader>
                <Trans>Payment methods for WooCommerce</Trans>
              </SectionHeader>
              <Trans parent="p">
                Whether you accept single payments or use the WooCommerce Subscriptions extension to
                schedule <BlogLink slug="what-is-recurring-payment">recurring payments</BlogLink>,
                accept all major{' '}
                <InternalPageLink slug="payment-methods">online payment methods</InternalPageLink>{' '}
                so your customers can pay with their preferred option.
              </Trans>
              <List>
                <Trans parent="li">
                  <Link to="/visa-mastercard-for-woocommerce/">Credit cards</Link> - Visa,
                  Mastercard, and more
                </Trans>
                <Trans parent="li">
                  Digital wallets -{' '}
                  <InternalPageLink slug="apple-pay-for-woocommerce">Apple Pay</InternalPageLink>,{' '}
                  <InternalPageLink slug="google-pay-for-woocommerce">Google Pay</InternalPageLink>,{' '}
                  <InternalPageLink slug="paypal-for-woocommerce">PayPal</InternalPageLink> or{' '}
                  <InternalPageLink slug="payment-methods/click-to-pay">
                    Click to Pay
                  </InternalPageLink>
                </Trans>
                <Trans parent="li">
                  Local payment methods such as{' '}
                  <InternalPageLink slug="bizum-for-woocommerce">Bizum</InternalPageLink>
                  (in Spain)
                </Trans>
                <Trans parent="li">
                  Direct Debit -{' '}
                  <InternalPageLink slug="payment-methods/sepa-direct-debit">SEPA</InternalPageLink>{' '}
                  and{' '}
                  <InternalPageLink slug="payment-methods/multibanco">Multibanco</InternalPageLink>
                </Trans>
              </List>
              <SectionActions>
                <ButtonLink href="/payment-methods/" variant="dark">
                  <Trans>View All Payment Methods</Trans>
                </ButtonLink>
              </SectionActions>
            </div>
          </Section>
          <Section reverseOnMobile>
            <div>
              <SectionHeader>
                <Trans>Custom WooCommerce checkout page</Trans>
              </SectionHeader>
              <Trans parent="p">
                Build trust, increase conversion rates, and avoid third-party redirects during the
                payment flow by{' '}
                <SupportLink slug="/articles/360017814717">customizing the appearance</SupportLink>{' '}
                of your checkout page with your logo, brand colors, and company domain.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Get MONEI Now</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
            <SectionImage
              src={index1}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={500}
              height={500}
              mobileWidth={280}
            />
          </Section>
          <Section>
            <SectionImage
              src={index3}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={400}
              height={428}
              mobileWidth={180}
            />
            <div>
              <SectionHeader>
                <Trans>Dynamic pricing</Trans>
              </SectionHeader>
              <Trans parent="p">
                Sell more and spend less on transaction fees. With MONEI’s dynamic{' '}
                <InternalPageLink slug="pricing">pricing</InternalPageLink> model, your payment
                gateway grows with your business.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
        </Content>
      </Background>
      <Content fullWidth>
        <Section textAlign="center">
          <div>
            <SectionHeader underline tagName="h3">
              <Trans>Easy integration</Trans>
            </SectionHeader>
            <Trans parent="p" style={{maxWidth: 800, margin: 'auto'}}>
              Use MONEI’s{' '}
              <AnchorLink target="_blank" href="https://wordpress.org/plugins/monei/">
                WooCommerce payment gateway plugin
              </AnchorLink>{' '}
              to connect with your e-commerce store in a few short steps. Wondering how it works?
              View a live WooCommerce demo{' '}
              <AnchorLink target="_blank" href="https://woocommerce-demo.monei.com/">
                here
              </AnchorLink>
              .
            </Trans>
            <Partners />
          </div>
        </Section>
      </Content>

      <Content>
        <Section centered column>
          <FaqsSection faqs={faqs} title={<Trans>WooCommerce payment gateway FAQ</Trans>} />
        </Section>
      </Content>
    </>
  );
};

export default SellingPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "woocommerce-payment-gateway"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
